import React from "react";
import NumberFormat from "react-number-format";
import Moment from 'react-moment';

const CaseInfoTable = ({ odyCase }) => {
    return (
        <>
            {odyCase != null && (
                <div className="jumbotron">
                    <h3>{odyCase.caseNbr}</h3>
                    <p>{odyCase.style}</p>
                    <table className="table table-condensed">
                        <tbody>
                            <tr>
                                <td>Case Type:</td>
                                <td>
                                    {
                                        odyCase.caseTypeCodeDescription
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Filed Date:</td>
                                <td>
                                    <Moment format="MM/DD/YYYY">
                                        {odyCase.dtFile}
                                    </Moment>
                                </td>
                            </tr>
                            <tr>
                                <td>Current Status:</td>
                                <td>
                                    {
                                        odyCase.statusCodeDescription
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Case Balance:</td>
                                <td>
                                    <NumberFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={
                                            odyCase.caseBalance
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};


export default CaseInfoTable;
