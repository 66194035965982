const paymentFields = [
    { name: 'Case Number', value: 'caseNumber', canEdit: false },
    { name: 'Envelope Number', value: 'envelopeNumber', placeHolder: 'Enter Envelope Number Here if Applicable' },
    { name: 'Amount', value: 'amountStr', type: 'money' },
    {
        name: 'Payment Method', value: 'payment_method',
        type: "select",
        selectHolder: "Please select a payment method",
        options: [
            { abbr: "card", name: "Card" },
            { abbr: "echeck", name: "E-Check (Bank)" }
        ]
    },
];


export default paymentFields;