import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faArrowLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import { getTransactionDetail } from "../actions/transaction-action";

const Receipt = ({
  // Redux props
  transactionDetail,
  emailTemplate,

  // Redux actions
  getTransactionDetail,
}) => {
  let { transactionID } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isAccepted, setIsAccepted] = useState();
  const [transDecision, setTransDecision] = useState("");

  const init = async (retryCount) => {
    const result = await getTransactionDetail(transactionID);
    if (!result) {
      // Cybersource needs time to update their data...
      // refresh every 3 seconds until it is not null anymore
      setTimeout(async () => {
        console.log(retryCount);
        if (retryCount > 30) {
          // Error transaction or not found in Cybersource
          setTimeout(() => {
            setLoading(false);
            setIsAccepted(false);
            setTransDecision("ERROR");
          }, 1000);
        } else await init(retryCount + 1);
      }, 3000);
    } else {
      if (result?.detail?.id != null && loading) {
        if (result.detail.decisionString === "ACCEPT") {
          setIsAccepted(true);
          const e = document.getElementsByClassName("receipt-wrapper")[0];
          if (e != null) {
            e.innerHTML = result.body;
            setLoading(false);
            setTransDecision("ACCEPT");
          }
        } else if (result.detail.decisionString === "DECLINE") {
          // Found but it is declined or error
          setTimeout(() => {
            setLoading(false);
            setIsAccepted(false);
            setTransDecision("DECLINE");
          }, 1000);
        }
      }
    }
  };
  useEffect(() => {
    init(1);
  }, []);

  const print = () => {
    const pri = document.getElementById("printArea");
    const mywindow = window.open("", "PRINT");
    mywindow.document.write(emailTemplate);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.document.title =
      transactionDetail.orderInformation.lineItems[0].productName
        .split("/")[0]
        .trim();
    mywindow.print();
    mywindow.close();

    return true;
  };

  return (
    <div className="col">
      <div>
        <div className="alert alert-warning payment-alert-warning">
          <p className="payment-alert-warning">
            <b>Do not submit multiple payments for this fee.</b> It may take up
            to 20 minutes for your payment to post. Please allow the system time
            to update.
          </p>
        </div>
      </div>

      {!loading && (
        <div className="row">
          <div className="col-7">
            <button
              className="btn btn-light"
              onClick={() => {
                history.push("/");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Make a New Payment
            </button>
          </div>

          {/* Print */}
          {isAccepted && (
            <div className="col-5">
              <div className="noprint">
                <button className="btn btn-primary btn-block" onClick={print}>
                  Print
                </button>
                <small>
                  Please keep a copy of this receipt for your records
                </small>
              </div>
            </div>
          )}
        </div>
      )}

      <hr />

      {/* Print Area */}
      <div id="printArea">
        <div className="receipt-wrapper mb-3"></div>
      </div>

      {/* If failed to accept */}
      {isAccepted === false && (
        <div>
          <div className="alert alert-warning">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ width: "30px", height: "30px", verticalAlign: "middle" }}
            />
            {transDecision === "ERROR" && (
              <span>
                &nbsp;{" "}
                <b>
                  Your payment failed to process. Please confirm that your
                  payment information is correct and try again.
                </b>
              </span>
            )}
            {transDecision === "DECLINE" && (
              <span>
                &nbsp;{" "}
                <b>
                  Your payment was declined. Please confirm your payment
                  information is correct and try again.
                </b>
              </span>
            )}
          </div>
        </div>
      )}

      {/* loading */}
      {loading && (
        <div>
          {
            <>
              <div
                className="alert alert-info"
                style={{ display: "flex", alignItems: "center" }}
              >
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin={true}
                  style={{ width: "20px", height: "20px" }}
                />{" "}
                <span>&nbsp; Loading receipt...</span>
              </div>
            </>
          }
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    transactionDetail: state.transactionReducer.transactionDetail?.detail,
    emailTemplate: state.transactionReducer.transactionDetail?.body,
  };
}

const mapDispatchToProps = {
  getTransactionDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
